import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ButtonLink } from "../components/ButtonLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { WrapperLight, WrapperLightGreen } from "../components/section-styling"
import styled from "styled-components"

import Seo from "../components/seo"

const BlogPostContent = styled.section`
  margin: auto;
  padding: 1.45rem 1.08rem;
  max-width: 960px;

  article {
    margin-top: 20px;
  }
`

export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]

  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />

      <WrapperLightGreen>
        <BlogPostContent>
          <h1>{post.title}</h1>
          <p>
            <i>Published {post.date}</i>
          </p>
        </BlogPostContent>
      </WrapperLightGreen>

      <WrapperLight>
        <BlogPostContent>
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (
            <GatsbyImage image={featuredImage.data} alt={featuredImage.alt} />
          )}

          <article dangerouslySetInnerHTML={{ __html: post.content }} />

          <ButtonLink to="/blog" className="light">
            SEE ALL POSTS
          </ButtonLink>
        </BlogPostContent>
      </WrapperLight>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        excerpt
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 95
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
